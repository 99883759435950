import { Button, makeStyles, Typography } from "@material-ui/core";
import { useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { MessageDialog } from "components/Common";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'end',
        marginBottom: '30px'
    },
    imageBox: {
        width: '100%',
        border: '1px solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    imageView: {
        // maxWidth: '100%',
        width: '100%',
        // maxHeight: '100%',
        height: 'auto',
        position: 'static',
    },
    btnUpload: {
        marginTop: '5px',
        marginBottom: '5px',
        marginRight: 0,
        margin: 'auto'
    },
    label: {},
    descriptionImageView: {
        display: 'flex',
        alignItems: 'center'
    },
    textImageView: {
        width: '80%',
        textAlign: 'left'
    },
}));

const ImageUploadPreview = (props: any) => {
    const { name } = props
    const [field, meta, helpers] = useField(props)
    const classes = useStyles();
    const [file, setFile] = useState(field.value)
    const [height, setHeight] = useState('300px')
    const refImageBox = useRef(null);
    const [isShowDialogError, setIsShowDialogError] = useState(false);
    const [message, setMessage] = useState("");



    const uploadSingleFile = (e: any) => {
        // const objectURL: any = URL.createObjectURL(e.target.files[0]).toString()
        // setFile(objectURL)
        let files = e.target.files;
        if (files.length > 0) {
            let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
            if (files[0].size > 10e6) {
                setMessage("画像サイズは10MB以内にしてください。")
                setIsShowDialogError(true)
                return
            }
            if (!allowedExtension.includes(files[0].type)) {
                setMessage("jpg,pngファイルのみ選択可能")
                setIsShowDialogError(true)
                return
            }

            let fileReader = new FileReader();
            fileReader.readAsDataURL(files[0]);

            fileReader.onload = (event: any) => {
                setFile(event.target.result)
            }
        }
    }
    useEffect(() => {
        helpers.setValue(file);
    }, [file]);

    useEffect(() => {
        let ele: any = refImageBox.current
        let width: number = ele ? ele.offsetWidth : 0
        if (width > 0) {
            setHeight(((width / 16) * 9) + "px")
        }
    }, [refImageBox.current]);

    const handleClick = () => {
        document.getElementById('input_upload_file_' + name)!.click()
    }
    const closeDialog = () => {
        setIsShowDialogError(false)
    }


    return (
        <>
            <Typography component="div" className={classes.root}>
                <div onClick={handleClick}>
                    <input id={"input_upload_file_" + name} hidden type="file" className="form-control" onChange={uploadSingleFile} />
                    <div ref={refImageBox} style={{ height: 'auto' }} className={classes.imageBox}>
                        {!file && '選択した画像を表示'}
                        {file && <img className={classes.imageView} src={file!} alt='' />}
                    </div>
                </div>
                <Typography component="div" className={classes.descriptionImageView}>
                    <Typography component="div" className={classes.textImageView}>
                        <Typography component="p" className={classes.label}>※jpg，pngファイルのみ選択可能</Typography>
                        <Typography component="p" className={classes.label}>※画像サイズは10MB以内にしてください。</Typography>
                    </Typography>

                    <Button className={classes.btnUpload} type="button" variant="contained"
                        color="primary" onClick={() => { setFile(null); (document.getElementById("input_upload_file_" + name) as HTMLInputElement)!.value = ""; }}>解除</Button>
                </Typography>
            </Typography>
            <MessageDialog isOpen={isShowDialogError} closeModal={closeDialog} title="確認" description={message} />
        </>
    )
}

export default ImageUploadPreview
