import { makeStyles, TextField, Typography } from "@material-ui/core";
import { useField } from "formik";
import React, { useState } from "react";
import { at } from "lodash";


const useStyles = makeStyles((theme) => ({
  errorCustom: {
    color: '#f44336',
    fontSize: ' 0.75rem',
    textAlign: 'left',
    marginBottom: "16px",
    marginLeft: '14px'
  }
}));

const InputPetName = (props: any) => {
  const { id, type = true, arrayPet, placeholder, maxLength, errorText, ...restProps } = props;
  const [field, meta, helpers] = useField(props);
  const classes = useStyles();
  const [isSameName, setIsSameName] = useState(false)

  const renderHelperText = () => {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  };
  const handleInputChange = (event: any) => {
    setIsSameName(false)
    const { value } = event.target;
    helpers.setValue(value)
    if (value && arrayPet.some((x: any) => x.name == value)) {
      setIsSameName(true)
    }
  }

  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        inputProps={{ maxLength: maxLength }}
        error={meta.touched && meta.error && true}
        helperText={renderHelperText()}
        id="standard-basic"
        {...field}
        {...restProps}
        fullWidth
        placeholder={placeholder}
        onChange={event => handleInputChange(event)}
      />
      {isSameName ? (
        <Typography component="p" className={classes.errorCustom}>愛犬・愛猫のお名前が存在しています。別の愛犬・愛猫のお名前を試してください。</Typography>
      ) : null}
    </>
  );
};

export default InputPetName;
