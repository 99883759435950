import { CustomerPetDrodown, CustomerPetOther, ListResponse } from "models"
import axiosClient from "./axiosClient"

const qrCodeApi = {
    getDataForDropdownPet(): Promise<ListResponse<CustomerPetDrodown>> {
        const url = "qrCode/getDataForDropdownPet.json"
        return axiosClient.get(url)
    },
    getDataForDropdownOthers(): Promise<ListResponse<CustomerPetOther>> {
        const url = "qrCode/getDataForDropdownOthers.json"
        return axiosClient.get(url)
    },
    addDataQRCode(data: any): Promise<any> {
        const url = "qrCode/addDataQRCode.json"
        return axiosClient.post(url, data)
    },
    checkInformationCustomer(data: any): Promise<any> {
        const url = "qrCode/checkInformationCustomer.json"
        return axiosClient.post(url, data)
    },
    
}

export default qrCodeApi