import React from 'react'

interface NotFounfProps {
}

export const NotFound = (props: NotFounfProps) => {
    return (
        <div>
            NotFound
        </div>
    )
}
 


