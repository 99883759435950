import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
    paper: { minWidth: "300px" },
}));

export const MessageDialog = (props: any) => {
    const { isOpen, title, description, descriptionLine2 = '', descriptionLine3 = '', closeModal, mode, agreeDialog } = props;
    const [open, setOpen] = useState(isOpen);
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
        closeModal()
    };
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.paper }}

            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        <p>{description}</p>
                        <p>{descriptionLine2}</p>
                        <p>{descriptionLine3}</p>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {mode === "choose" && <Button onClick={agreeDialog}>はい</Button>}
                    {mode === "choose" && <Button onClick={handleClose}>いいえ</Button>}
                    {!mode && <Button onClick={() => handleClose()} autoFocus>
                        閉じる
                    </Button>}

                </DialogActions>
            </Dialog>
        </div>
    );
}



