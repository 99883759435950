import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useField } from "formik";
import { at } from "lodash";
import React, { useEffect, useState } from "react";



const renderOptions = (options: any) => {
    return options.map((option: any, index: number) => (
        <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
        />
    ));
};

const RadioButton = (props: any) => {
    const { name, options } = props
    const [valueSelected, setValueSelected] = useState(options[0].value);
    const [field, meta, helpers] = useField(props)
    const [touched, error] = at(meta, "touched", "error");
    const { setValue } = helpers

    useEffect(() => {
        setValue(field?.value ? field?.value : options[0].value);
        setValueSelected(field?.value ? field?.value : options[0].value)
    }, []);


    const handleChange = (events: any) => {
        setValueSelected(events.target.value);
        setValue(events.target.value);
    };

    return (
        <React.Fragment>
            <RadioGroup
                {...field}
                {...props}
                style={{flexDirection: "inherit"}}
                name={name}
                value={valueSelected}
                onChange={handleChange}
            >
                {options && renderOptions(options)}
            </RadioGroup>

            {/* {touched[fieldName] && errors[fieldName] && (
                <span style={{ color: "red", fontFamily: "sans-serif" }}>
                    {errors[fieldName]}
                </span>
            )} */}
        </React.Fragment>
    );
};

export default RadioButton;
