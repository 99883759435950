import { FormControl, FormHelperText, makeStyles } from "@material-ui/core";
import { useField } from "formik";
import { at } from "lodash";
import React from "react";
import Select from "react-select";
import { ValueType } from "react-select/lib/types";

interface Option {
  label: string;
  value: string;
}


const useStyles = makeStyles((theme) => ({
  error: {
    color: '#f44336',
    margin: "0 14px"
  },
  errorSmall: {
    color: '#f44336',
    margin: "0",
    fontSize: "0.65rem"
  }
}));


const SelectField = (props: any) => {
  const classes = useStyles();
  const { name, className, placeholder, options, isMulti, isSmallMessage = false, isDisabledSelect = false, data, form } = props
  const [field, meta, helpers] = useField(props)
  const [touched, error] = at(meta, "touched", "error");
  const { setValue } = helpers
  const onChange = (option: ValueType<Option | Option[]>) => {
    setValue(
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: any) => field.value.indexOf(option.value) >= 0)
        : options.find((option: any) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };
  const renderHelperText = () => {
    if (touched && error) {
      return <FormHelperText className={isSmallMessage ? classes.errorSmall : classes.error}>{error}</FormHelperText>
    }
  }

  const customStyles: any = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: '1px dotted rgba(0, 0, 0, 0.23)',
      color: state.isSelected ? '#fff' : '#000',
      padding: 10,
      fontWeight: 'normal',
      fontSize: '1rem',
      backgroundColor: state.isSelected ? '#85a3d9' : '#fff',
      textAlign: 'left',
    }),

    control: (provided: any, state: any) => {
      const borderColor = touched && error ?  '#f44336' : 'rgba(0, 0, 0, 0.23)';
      const fontWeight = 'normal';
      const textAlign = 'left';
      return { ...provided, borderColor, fontWeight, textAlign };
    },

    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }

  // touched && error ? customStyles.control =  (provided:any) => ({...provided,borderColor: '#f44336'}) : null

  return (
    <FormControl style={{ width: '100%' }}>
      <Select
        styles={customStyles}
        className={className}
        name={name}
        value={getValue()}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        isDisabled={isDisabledSelect}
      />
      {renderHelperText()}
    </FormControl>
  );
};

export default SelectField;
