import * as yup from "yup"
import { formModel, formPetModel } from "./form-model"
const {
  formField: {
    familyName,
    firstName,
    kanaFamilyName,
    kanaFirstName,
    emailAddress,
    verifyEmailAddress,
    phoneNumber,
    postalCode,
    prefectures,
    address1,
    address2,
    customerPets,
  },
} = formModel
const {
  formField: {
    petName,
    brandId,
    petId,
    birthdayYear,
    birthdayMonth,
    birthdayDay
  },
} = formPetModel

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const postalCodeRegExp = /^\d{7}?$/

export const validationSchema = [
  yup.object().shape({
    [familyName.name]: yup.string().required(familyName.requiredErrorMsg),
    [firstName.name]: yup.string().required(firstName.requiredErrorMsg),
    [kanaFamilyName.name]: yup.string().required(kanaFamilyName.requiredErrorMsg),
    [kanaFirstName.name]: yup.string().required(kanaFirstName.requiredErrorMsg),
    [emailAddress.name]: yup.string().email(emailAddress.invalidErrorMsg).required(emailAddress.requiredErrorMsg),
    [verifyEmailAddress.name]: yup.string().email(verifyEmailAddress.invalidErrorMsg).required(verifyEmailAddress.requiredErrorMsg).oneOf([yup.ref('email_address'), null], verifyEmailAddress.verifyEmail),
    [phoneNumber.name]: yup.string().trim().required(phoneNumber.requiredErrorMsg).matches(phoneRegExp, phoneNumber.formatPhoneMsg),
    [postalCode.name]: yup.string().trim().required(postalCode.requiredErrorMsg).matches(postalCodeRegExp, postalCode.formatPostalCodeMsg),
    [prefectures.name]: yup.string().required(`${prefectures.requiredErrorMsg}`),
    [address1.name]: yup.string().required(`${address1.requiredErrorMsg}`),
    [address2.name]: yup.string().required(`${address2.requiredErrorMsg}`),
  }),
  yup.object().shape({
    [customerPets.name]: yup.array().of(
      yup.object().shape({
        [petName.name]: yup.string().required(petName.requiredErrorMsg),
        // [brandId.name]: yup.string().required(brandId.requiredErrorMsg),
        [petId.name]: yup.string().required(petId.requiredErrorMsg),
        // [birthdayYear.name]: yup.string().required(birthdayYear.requiredErrorMsg),
        // [birthdayMonth.name]: yup.string().required(birthdayMonth.requiredErrorMsg),
        // [birthdayDay.name]: yup.string().required(birthdayDay.requiredErrorMsg),
      })
    ).ensure()
      .min(1, "Need at least a pet"),
  })
]


