import { Button, Icon, makeStyles, Typography, useTheme } from "@material-ui/core";
import { ControlPoint, ControlPointDuplicateTwoTone, ControlPointRounded, RemoveCircleOutline, RemoveCircleRounded } from "@material-ui/icons";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
  },
  btnTerm: {
    width: "100%",
    marginBottom: "10px",
    backgroundColor: "#000 !important",
    color: "#fff",
  },
  descriptionTermHidden: {
    height: 0,

  },
  descriptionTerm: {
    display: "inline-block",
    // height: '1350px !important',
    height: 'auto !important'
  },
  term: {
    textAlign: "left",
    height: 0,
    transition: "height 0.7s linear",
    overflow: "hidden",
  },
  iconPlus: {
    margin: 'auto',
    marginRight: 0
  }
}));
interface TermPageProps { }

export const TermPage = (props: TermPageProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpenTerm, setIsOpenTerm] = useState(false);
  return (
    <>
      <Button
        className={classes.btnTerm}
        onClick={() => setIsOpenTerm(!isOpenTerm)}
        variant="contained"
      >
        プライバシーポリシーとは
        {!isOpenTerm && <ControlPointRounded className={classes.iconPlus} />}
        {isOpenTerm && <RemoveCircleOutline className={classes.iconPlus} />}
      </Button>
      <Typography
        component="div"
        className={`${classes.term}
          ${isOpenTerm ? classes.descriptionTerm : classes.descriptionTermHidden
          }`}
      >
        <Typography component="p" style={{ marginBottom: theme.spacing(2) }}>
          株式会社ベッツ・チョイス・ジャパン（以下
          弊社とする）が運営するウェブサイト（以下「本サイト」といいます）において、お客様の個人情報をお預かりすることになりますが、そのお預かりした個人情報の取扱について、下記のように定め、保護に努めております。
        </Typography>

        <Typography component="p" >【利用目的】</Typography>
        <Typography component="p">
          (1) お問い合わせに対する回答を行うため
        </Typography>
        <Typography component="p">
          (2) サービス実施のため
        </Typography>
        <Typography component="p">
          (3) サービス、催し物のご案内のため
        </Typography>
        <Typography component="p" style={{ marginBottom: theme.spacing(2) }}>
          (4) その他将来提供されるサービス
        </Typography>

        <Typography component="p">【個人情報とは】</Typography>
        <Typography component="p">弊社が取得および保有する個人情報（以下「個人情報」といいます）は、以下の通りとします。</Typography>
        <Typography component="div">
          <Typography component="p" style={{ marginLeft: theme.spacing(2) }}>
            (1) 本サイトのお客様情報を登録時に記載した、以下の登録情報
          </Typography>
          <Typography component="p" style={{ marginLeft: theme.spacing(2) }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;お名前、性別、生年月日、電話番号、住所、メールアドレス、愛犬・愛猫の情報、ログイン情報等
          </Typography>
        </Typography>
        <Typography component="p" style={{ marginLeft: theme.spacing(2) }}>
          (2) 本サイトでの活動内容（アンケート回答結果、購入履歴、キャンペーン参加履歴、閲覧履歴、Cookie等）
        </Typography>
        <Typography component="p" style={{ marginBottom: theme.spacing(2), marginLeft: theme.spacing(2)  }}>
          (3) その他、個人情報保護法を遵守した上で、弊社が取得するあらゆる個人情報
        </Typography>

        <Typography component="p">【第三者への提供】</Typography>
        <Typography component="p" style={{ marginBottom: theme.spacing(2) }}>弊社は法律で定められている場合を除いて、お客様の個人情報を当該本人の同意を得ず第三者に提供することはありません。</Typography>

        <Typography component="p">【個人情報の取扱い業務の委託】</Typography>
        <Typography component="p" style={{ marginBottom: theme.spacing(2) }}>弊社は事業運営上、お客様により良いサービスを提供するために業務の一部を外部に委託しており、業務委託先に対してお客様の個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客様の個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。</Typography>

        <Typography component="p">【個人情報提供の任意性】</Typography>
        <Typography component="p" style={{ marginBottom: theme.spacing(2) }}>お客様が弊社に対して個人情報を提供することは任意です。ただし、個人情報を提供されない場合には、弊社からの返信やサービスの提供ができない場合がありますので、あらかじめご了承ください。</Typography>

        <Typography component="p">【個人情報の開示請求について】</Typography>
        <Typography component="p" style={{ marginBottom: theme.spacing(2) }}>弊社には、お客様の個人情報の利用目的の通知、開示、訂正、追加、削除および利用又は提供の拒否権を要求する権利があります。詳細につきましては下記の窓口までご連絡下さい。</Typography>

        <Typography component="p">【個人情報問合せ窓口】</Typography>
        <Typography component="p">個人情報についてのお問合せに関しては、下記窓口までお願いいたします。</Typography>
        <Typography component="p">株式会社ベッツ・チョイス・ジャパン</Typography>
        <Typography component="p">〒486-0802 愛知県春日井市桃山町3丁目105番地</Typography>
        <Typography component="p">TEL：0120-09-1067</Typography>
      </Typography>
    </>
  );
};
