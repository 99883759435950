import { makeStyles, Typography } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import SelectField from './SelectField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  dropdown: {
    marginRight: "5px",
  },
  year: {
    width: "50%",
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  monthDay: {
    width: "100%",
    display: "flex",
    alignItems: 'center',
  },
  label: {
    width: "10%",
    marginRight: "30px",
  }

}));

const getYearArr = () => {
  let year = [0];
  const currentYear = (new Date()).getFullYear();
  for (var i = currentYear; i >= (currentYear - 31); i--) {
    year.push(i);
  }
  return year;
}

const SelectDatePicker = (props: any) => {
  const { indexKey, isDisabledSelect } = props
  const classes = useStyles();
  const [value, setValue] = useState<Date | null>();


  const onDateChange = useCallback((date: Date) => {
    setValue(date);
  }, []);

  let days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  let months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  let years = getYearArr();
  // const optionsDay = days.map((day: number) => ({ value: day === 0 ? '' : day, label: day === 0 ? '日' : (day + ' 日') }))
  // const optionsMonths = months.map((month: number) => ({ value: month === 0 ? '' : month, label: month === 0 ? '月' : (month + ' 月') }))
  // const optionsYears = years.map((year: number) => ({ value: year === 0 ? '' : year, label: year === 0 ? '年' : (year + ' 年') }))
  const optionsDay = days.map((day: number) => ({ value: day === 0 ? '' : day, label: day === 0 ? '--' : day }))
  const optionsMonths = months.map((month: number) => ({ value: month === 0 ? '' : month, label: month === 0 ? '--' : month }))
  const optionsYears = years.map((year: number) => ({ value: year === 0 ? '' : year, label: year === 0 ? '--' : year }))

  return (
    <>
      <Typography component="div" className={classes.root}>
        <Typography component="div" className={classes.year}>
          <SelectField
            className={classes.dropdown}
            options={optionsYears}
            name={`customerPets.${indexKey}.birthdayYear`}
            placeholder=""
            isMulti={false}
            isSmallMessage={true}
            key={'year' + indexKey}
            isDisabledSelect={isDisabledSelect}
          />
          <Typography component="span" className={classes.label}>年</Typography>
        </Typography>
        <Typography component="div" className={classes.monthDay}>
          <SelectField
            className={classes.dropdown}
            options={optionsMonths}
            name={`customerPets.${indexKey}.birthdayMonth`}
            placeholder=""
            isMulti={false}
            isSmallMessage={true}
            key={'month' + indexKey}
            isDisabledSelect={isDisabledSelect}
          />
          <Typography component="span" className={classes.label}>月</Typography>
          <SelectField
            className={classes.dropdown}
            options={optionsDay}
            name={`customerPets.${indexKey}.birthdayDay`}
            placeholder=""
            isMulti={false}
            isSmallMessage={true}
            key={'day' + indexKey}
            isDisabledSelect={isDisabledSelect}
          />
          <Typography component="span" className={classes.label}>日</Typography>
        </Typography>
      </Typography>
    </>
  );
};

export default SelectDatePicker;
