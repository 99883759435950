import {
  Button,
  CircularProgress, Step,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import { MessageDialog } from "components/Common/MessageDialog";
import CustomerForm from "features/forms/customer";
import CustomerOtherForm from "features/forms/customer-other";
import CustomerPetsForm from "features/forms/customer-pet";
import RegisteSuccess from "features/forms/register-success";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import React, { useState } from "react";
import { formModel } from "utils/form-model";
import { initialValues } from "utils/initial-values";
import { validationSchema } from "utils/validation-schema";
import { useHomeStyles } from "./Home.styles";
import logo from "./logo.png";
import qrCodeApi from "api/qrCodeApi";
import { checkDateValid } from "utils";

interface Props { }

const { formField } = formModel;
const steps = ["お客様情報入力", "愛犬・愛猫情報入力", "その他入力"];

export const HomePage = (props: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [message, setMessage] = useState('');
  const [messageLine2, setMessageLine2] = useState('');
  const [messageLine3, setMessageLine3] = useState('');
  const [mode, setMode] = useState('');
  const selectedValidationSchema = validationSchema[activeStep];
  const isLast = activeStep === steps.length - 1;
  const styles = useHomeStyles();
  const sleep = (time: number) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const renderStepContent = (step: number, formikProps: any) => {
    switch (step) {
      case 0:
        return <CustomerForm formField={formField} errors={formikProps.errors} touched={formikProps.touched} />;
      case 1:
        return (
          <CustomerPetsForm formikProp={formikProps} formField={formField} />
        );
      case 2:
        return (
          <CustomerOtherForm
            formField={formField}
            values={formikProps.values}
          />
        );
      case 3:
        return <RegisteSuccess />;
    }
  };

  const submitForm = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    let isError: boolean = false

    if (values.imageFile1 && values.petNameImg1s.length <= 0) {
      actions.setFieldError('petNameImg1s', '写真１の愛犬・愛猫のお名前を入力してください。')
      isError = true
    }
    if (values.imageFile2 && values.petNameImg2s.length <= 0) {
      actions.setFieldError('petNameImg2s', '写真２の愛犬・愛猫のお名前を入力してください。')
      isError = true
    }

    if (isError) {
      actions.setSubmitting(false);
      return
    }

    values.customerOthers = []
    if (values.shopIdOther) {
      let other = {
        category: 1,
        category_id: values.shopIdOther
      }
      values.customerOthers.push(other)
    }

    if (values.campaignId && values.campaignId.length > 0) {
      values.campaignId.forEach((x: number) => {
        let other = {
          category: 3,
          category_id: x
        }
        values.customerOthers.push(other)
      })
    }

    qrCodeApi.addDataQRCode(values).then((response: any) => {
      let result = response.result
      if (result) {
        if (result.status == 'success') {
          console.log(values);
          actions.setSubmitting(false);
          setActiveStep((prev) => prev + 1);
        } else {
          alert(result.message)

        }
      }
    })

  };

  const closeDialog = () => {
    setIsShowDialog(false)
  }
  const agreeDialog = () => {
    setActiveStep((prev) => prev + 1);
    setIsShowDialog(false)
  }

  const checkBirthday = (arrayPets: any, actions: FormikHelpers<FormikValues>) => {
    let isError = false
    arrayPets.forEach((item: any, index: number) => {
      if (item.birthdayDefault) {
        item.birthday = '1900-01-01'
      } else {
        if (checkDateValid(item.birthdayYear, item.birthdayMonth, item.birthdayDay)) {
          item.birthday = item.birthdayYear + '-' + item.birthdayMonth + '-' + item.birthdayDay;
        }
        else {
          actions.setFieldError(`customerPets[${index}].birthday`, '正しく日付を入力してください')
          isError = true
        }
      }
    })
    return isError
  }

  const handleSubmit = (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (isLast) {
      submitForm(values, actions);
    } else {
      let isError = false;
      if (isNextStep) {
        if (activeStep == 0) {
          qrCodeApi.checkInformationCustomer(values).then((response: any) => {
            let result = response.result
            if (result) {
              if (result.status == 'fail') {
                setIsShowDialog(true)
                setMessage(result.message)
                if (result.message1) setMessageLine2(result.message1)
                if (result.message2) setMessageLine3(result.message2)
              } else {
                if (result.mode && result.mode == 'choose') {
                  setIsShowDialog(true)
                  setMessage(result.message)
                  setMode(result.mode)
                } else {
                  setActiveStep((prev) => prev + 1);
                }
              }
            }
          })
        } else if (activeStep == 1) {
          if (values.customerPets.length > 0) {

            if (values.customerPets.every((item: any) => checkDateValid(item.birthdayYear, item.birthdayMonth, item.birthdayDay) ||
              (!checkDateValid(item.birthdayYear, item.birthdayMonth, item.birthdayDay) && item.birthdayDefault))) {
              setActiveStep((prev) => prev + 1);
            }
            isError = checkBirthday(values.customerPets, actions)
          }
        } else {
          setActiveStep((prev) => prev + 1);
        }
      } else {
        if (values.customerPets.length > 0) {
          isError = checkBirthday(values.customerPets, actions)
        }
      }
      if (!isError) actions.setTouched({});
      actions.setSubmitting(false);
    }
    setIsNextStep(false);
  };

  const textButtonBack = "< 戻る"

  return (
    <>
      <div className={styles.root}>
        <Typography component="div" className={styles.logo}>
          <img src={logo} />
        </Typography>
        {activeStep !== 3 && <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={styles.stepperCustome}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>}
        <Formik
          initialValues={initialValues}
          validationSchema={selectedValidationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              {renderStepContent(activeStep, formikProps)}
              {activeStep !== 3 && (
                <div className={styles.buttons}>
                  {activeStep !== 0 && (
                    <Button
                      onClick={() => setActiveStep((prev) => prev - 1)}
                      className={styles.button}
                    >
                      {textButtonBack}
                    </Button>
                  )}

                  <div className={styles.wrapper}>
                    <Button
                      // style={{ backgroundColor: '#85a3d9', color: '#fff'}}
                      type="submit"
                      variant="contained"
                      onClick={
                        () => setIsNextStep(true)
                        // formikProps.submitForm().then(() => {
                        //   if (formikProps.isValid) {
                        //     setActiveStep((prev) => prev + 1);
                        //     formikProps.setTouched({});
                        //     formikProps.setSubmitting(false);
                        //   }
                        // })
                      }
                      color="primary"
                      className={styles.button}
                      disabled={formikProps.isSubmitting || (isLast && (!formikProps.values.agreeTerm || !formikProps.values.agreeTerm1 || !formikProps.values.agreeTerm2))}
                    >
                      {isLast ? "登録完了" : "次へ進む"}
                      {formikProps.isSubmitting && <CircularProgress style={{ marginLeft: '10px' }} color="inherit" size={20} />}
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
        <MessageDialog isOpen={isShowDialog} closeModal={closeDialog} agreeDialog={agreeDialog} title="確認"
          description={message} descriptionLine2={messageLine2} descriptionLine3={messageLine3} mode={mode} />
      </div>
    </>
  );
};

export default HomePage;
