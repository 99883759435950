import {
  Box,
  BoxProps,
  Button,
  makeStyles,
  Typography
} from "@material-ui/core";
import qrCodeApi from "api/qrCodeApi";
import { TermPage } from "components/Common";
import CheckboxField from "components/Common/form-fields/CheckboxField";
import ImageUploadPreview from "components/Common/form-fields/ImageUploadPreview";
import InputField from "components/Common/form-fields/InputField";
import SelectField from "components/Common/form-fields/SelectField";
import LabelTag from "components/Common/LabelTag";
import { FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";

interface PropsType {
  formField: FormikValues;
  values: any;
}


function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: 19,
        fontWeight: "700",
        height: "auto",
        ...sx,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "30px",
    width: "100%",
    display: "contents",
  },
  title: {
    backgroundColor: "rgb(255, 255, 255)",
    fontWeight: "bold",
    fontSize: "1.4em",
    padding: "30px",
  },
  btnFull: {
    width: "100%",
    marginBottom: "10px",
  },
  label: {
    color: '#000',
    marginLeft: "5px",
    textAlign: "left",
  },
  text: {
    display: "grid",
    textAlign: "left"
  }
}));

const CustomerOtherForm: FC<PropsType> = (props) => {
  const {
    formField: { shopIdOther, campaignId, petIdImg1s, petIdImg2s, petNameImg1s, petNameImg2s, imageFile1, imageFile2, agreeTerm, agreeTerm1, agreeTerm2 },
    values,
  } = props;
  const classes = useStyles();
  const optionsPets = values.customerPets.map((x: any) => {
    return { label: x.name, value: x.name };
  });
  const [campaigns, setCampaigns] = useState([])
  const [shops, setShops] = useState([])


  useEffect(() => {
    window.scrollTo(0, 0);
    qrCodeApi.getDataForDropdownOthers().then((response: any) => {
      if (response) {
        setCampaigns(response?.campaigns?.map((x: any) => { return { label: x.name, value: x.id } }))
        setShops(response?.shops?.map((x: any) => { return { label: x.name, value: x.id } }))
      }
    })
  }, []);

  const handleClickAddImage = (nameInput: string) => {
    document.getElementById("input_upload_file_" + nameInput)!.click();
  };

  return (
    <>
      <Typography component="div" className={classes.root}>
        <Typography className={classes.title}>その他の入力</Typography>
        <Box sx={{ display: "grid" }}>
          {/*prefectures*/}
          {/* <Item>
            <LabelTag titleLabel="よく購入する店舗" isRequired={false} />
            <SelectField
              className="custom-select"
              name={shopIdOther.name}
              options={shops}
              placeholder=""
              isMulti={false}
            />
          </Item>
          <Item>
            <LabelTag
              titleLabel=" 過去の参加キャンペーン参加"
              isRequired={false}
            />
            <SelectField
              className="custom-select"
              name={campaignId.name}
              options={campaigns}
              placeholder=""
              isMulti={true}
            />
          </Item> */}
          <Typography className={classes.title}>写真の応募</Typography>
          <Item>
            <Button
              className={classes.btnFull}
              variant="contained"
              color="primary"
              onClick={() => handleClickAddImage(imageFile1.name)}
            >
              写真を選択
            </Button>
            <ImageUploadPreview name={imageFile1.name} />
            {/* <SelectField
              className="custom-select"
              name={petIdImg1s.name}
              options={optionsPets}
              placeholder=""
              isMulti={true}
            /> */}
            <Typography component="div" className={classes.text}>
              <Typography component="span" className={classes.label}>写真の愛犬・愛猫のお名前</Typography>
            </Typography>
            <InputField name={petNameImg1s.name} maxLength={50} />
            <Typography component="div" className={classes.text}>
              <Typography component="span" className={classes.label}>※カレンダーの写真に選ばれた場合はこちらにご入力いただいたお名前が表示される予定ですので注意してご入力ください。</Typography>
            </Typography>
          </Item>
          {/*<Item>
            <Button
              className={classes.btnFull}
              variant="contained"
              color="primary"
              onClick={() => handleClickAddImage(imageFile2.name)}
            >
              （2）写真を選択
            </Button>
            <ImageUploadPreview name={imageFile2.name} />
             <SelectField
              className="custom-select"
              name={petIdImg2s.name}
              options={optionsPets}
              placeholder=""
              isMulti={true}
            /> 
            <Typography component="div" className={classes.text}>
              <Typography component="span" className={classes.label}>写真の愛犬・愛猫のお名前2</Typography>
            </Typography>
            <InputField name={petNameImg2s.name} maxLength={50} />
            <Typography component="div" className={classes.text}>
              <Typography component="span" className={classes.label}>※カレンダーの写真に選ばれた場合はこちらにご入力いただいたお名前が表示される予定ですので注意してご入力ください。</Typography>
            </Typography>
          </Item>*/}
          <Item>
            <Typography component="div" style={{ display: 'flex' }}>
              <Typography component="p" className={classes.label}>ご応募頂く写真は、お客様ご自身が撮影されたものであり、著作権を侵害するものは含まれていない。</Typography>
            </Typography>
            <Typography component="div" style={{ display: 'flex' }}>
              <CheckboxField
                name={agreeTerm1.name}
                label="はい　"
              />
            </Typography>
          </Item>
          <Item>
            <Typography component="div" style={{ display: 'flex' }}>
              <Typography component="p" className={classes.label}>ご応募頂いた愛犬・愛猫の写真について、(株)ベッツ・チョイス・ジャパンの各種プロモーション（カタログ・ホームページ・SNS・パンフレット等）に使用されることを了承する。</Typography>
            </Typography>
            <Typography component="div" style={{ display: 'flex' }}>
              <CheckboxField
                name={agreeTerm2.name}
                label="はい　"
              />
            </Typography>
          </Item>
          <Item>
            <TermPage />
          </Item>
          <Item>
            <Typography component="div" style={{ display: 'flex' }}>
              <Typography component="p" className={classes.label}>プライバシーポリシーに同意し、登録完了へ進む。</Typography>
            </Typography>
            <Typography component="div" style={{ display: 'flex' }}>
              <CheckboxField
                name={agreeTerm.name}
                label="はい　"
              />
            </Typography>
          </Item>
        </Box>
      </Typography>
    </>
  );
};

export default CustomerOtherForm;
