export const formModel = {
  formId: "customerQRForm",
  formField: {
    identificationNumber: {
      name: "identification_number",
    },
    familyName: {
      name: "family_name",
      requiredErrorMsg: "名前（姓）を入力してください。",
    },
    firstName: {
      name: "first_name",
      requiredErrorMsg: "名前（名）を入力してください。",
    },
    kanaFamilyName: {
      name: "kana_family_name",
      requiredErrorMsg: "ふりがな（姓）を入力してください。",
    },
    kanaFirstName: {
      name: "kana_first_name",
      requiredErrorMsg: "ふりがな（名）を入力してください。",
    },
    gender: {
      name: "gender",
    },
    emailAddress: {
      name: "email_address",
      requiredErrorMsg: "メールアドレス を入力してください。",
      invalidErrorMsg: "メールアドレス形式で入力してください。",
    },
    verifyEmailAddress: {
      name: "verifyEmailAddress",
      requiredErrorMsg: " もう一度メールアドレス入力  を入力してください。",
      verifyEmail: "メールアドレスが一致しないです。再度確認お願いします。",
      invalidErrorMsg: "メールアドレス形式で入力してください。",
    },
    phoneNumber: {
      name: "phone_number",
      requiredErrorMsg: "電話番号（ハイフンなし) を入力してください。",
      formatPhoneMsg: "電話番号を正しく形で入力してください。",
    },
    postalCode: {
      name: "postal_code",
      requiredErrorMsg: "郵便番号入力してください。",
      formatPostalCodeMsg: '郵便番号を正しく形で入力してください。'
    },
    prefectures: {
      name: "prefectures",
      requiredErrorMsg: "都道府県を入力してください。",
    },
    address1: {
      name: "address1",
      requiredErrorMsg: "住所を入力してください。",
    },
    address2: {
      name: "address2",
      requiredErrorMsg: "丁目・番・号 を入力してください。",
    },
    address3: {
      name: "address3",
    },
    customerPets: {
      name: "customerPets",
    },
    shopIdOther: {
      name: "shopIdOther",
    },
    campaignId: {
      name: "campaignId",
    },
    petIdImg1s: {
      name: "petIdImg1s",
    },
    petIdImg2s: {
      name: "petIdImg2s",
    },
    petNameImg1s: {
      name: "petNameImg1s",
    },
    petNameImg2s: {
      name: "petNameImg2s",
    },
    imageFile1: {
      name: "imageFile1",
    },
    imageFile2: {
      name: "imageFile2",
    },
    petNameSeleted: {
      name: "petNameSeleted",
    },
    agreeTerm: {
      name: "agreeTerm",
    },
    agreeTerm1: {
      name: "agreeTerm1",
    },
    agreeTerm2: {
      name: "agreeTerm2",
    },
  },
}
export const formPetModel = {
  formField: {
    petName: {
      name: "name",
      requiredErrorMsg: "愛犬・愛猫の名前 を入力してください。",
    },
    kinds: {
      name: "kinds",
    },
    brandId: {
      name: "brand_id",
      requiredErrorMsg: " よく購入するブランド を入力してください。",
    },
    petId: {
      name: "pet_type_id",
      requiredErrorMsg: "愛犬・愛猫の種類 を入力してください。",
    },
    genderPet: {
      name: "gender",
    },
    castration: {
      name: "castration",
    },
    birthdayYear: {
      name: "birthdayYear",
      requiredErrorMsg: "年 を入力してください。",
    },
    birthdayMonth: {
      name: "birthdayMonth",
      requiredErrorMsg: "月 を入力してください。",
    },
    birthdayDay: {
      name: "birthdayDay",
      requiredErrorMsg: "日 を入力してください。",
    },
    birthday: {
      name: "birthday",
    },
    shopId: {
      name: "purchase_shop_id",
    },
    birthdayDefault: {
      name: "birthdayDefault",
    },
  },
}
