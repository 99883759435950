import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        marginBottom: "10px"
    },
    tag: {
        minWidth: '50px',
        height: '30px',
        color: '#fff',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: '4px',
    },
    bgColorNotRequire: {
        backgroundColor: "#3EB36D",
    },
    bgColorColorRequired: {
        backgroundColor: "#f44336",
    },
    label: {
        color: '#000',
        marginLeft: "5px",
    },
    text: {
        display: "grid",
        textAlign: "left"
    }
}));
const LabelTag = (props: any) => {

    const { titleLabel, isRequired, description, isDescription = false } = props
    const classes = useStyles();

    return (
        <>
            <Typography component="div" className={classes.root} style={!isDescription ? {alignItems: "center"} : {}}>
                <Typography component="div" className={[classes.tag, (isRequired == true ? classes.bgColorColorRequired : classes.bgColorNotRequire)].join(" ")}>
                    {isRequired ? '必須' : '任意'}</Typography>
                <Typography component="div" className={classes.text}>
                    <Typography component="span" className={classes.label}>{titleLabel}</Typography>
                    <Typography component="span" className={classes.label}>{description}</Typography>
                </Typography>
            </Typography>
        </>
    )
}

export default LabelTag
