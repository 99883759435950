import {
  Box,
  BoxProps,
  Button, makeStyles, Typography
} from "@material-ui/core";
import qrCodeApi from "api/qrCodeApi";
import CheckboxField from "components/Common/form-fields/CheckboxField";
import InputPetName from "components/Common/form-fields/InputPetName";
import RadioButton from "components/Common/form-fields/RadioButton";
import RadioButtonForKinds from "components/Common/form-fields/RadioButtonForKinds";
import SelectDatePicker from "components/Common/form-fields/SelectDatePicker";
import SelectField from "components/Common/form-fields/SelectField";
import LabelTag from "components/Common/LabelTag";
import { ErrorMessage, FieldArray, FormikValues, useField } from "formik";
import React, { FC, useEffect, useState } from "react";
import { checkDateValid } from "utils";


function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: 19,
        fontWeight: "700",
        height: "auto",
        ...sx,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "30px",
    width: "100%",
    display: "contents",
  },
  title: {
    backgroundColor: "rgb(255, 255, 255)",
    fontWeight: "bold",
    fontSize: "1.4em",
    padding: "30px",
  },
  itemPet: {
    marginBottom: "50px",
  },
  buttonRemove: {
    textAlign: "end",
    padding: "0 5px",
    backgroundColor: "rgb(255, 255, 255)",
  },
  boxPet: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    margin: "0 5px",
    borderRadius: "4px",
  },
  boxListPet: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5px",
    marginBottom: '20px'
  },
  newPet: {
    width: "200px",
    marginLeft: '10px'
  },
  errorCustom: {
    color: '#f44336',
    fontSize: ' 0.75rem',
    textAlign: 'left',
    marginBottom: "16px",
    marginLeft: '14px'
  },
  fieldError: {
    color: '#f44336',
    marginLeft: '14px',
    marginTop: '4px',
    textAlign: 'left',
    fontSize: '0.75rem'

  }
}));
interface PropsType {
  formField: FormikValues;
  formikProp: any;
}

const CustomerPetsForm: FC<PropsType> = (props) => {
  const {
    formField: { petNameSeleted },
    formikProp,
  } = props;
  const [fieldPrefectures, metaPrefectures, helpers] = useField(
    petNameSeleted.name
  );
  const classes = useStyles();
  const optionPets = formikProp.values.customerPets.map(
    (x: any, index: number) => {
      return { label: (index + 1) + " 頭目の愛犬・愛猫情報" + ": " + (x.name ? x.name : ""), value: index };
    }
  );
  const [brands, setBrands] = useState([])
  const [petTypes, setPetTypes] = useState([])
  const [resPetTypes, setResPetTypes] = useState([])
  const [shops, setShops] = useState([])
  const optionRadioKinds = [{ label: '犬', value: '1' }, { label: '猫', value: '2' }];
  const optionRadioGenderPet = [{ label: 'オス', value: '1' }, { label: 'メス', value: '2' }];
  const optionRadioCastration = [{ label: 'なし', value: '1' }, { label: 'あり', value: '2' }];


  useEffect(() => {
    window.scrollTo(0, 0)
    qrCodeApi.getDataForDropdownPet().then((response: any) => {
      if (response) {
        setBrands(response?.brands?.map((x: any) => { return { label: x.name, value: x.id } }))
        setResPetTypes(response?.petTypes)
        let kinds: number = 1
        if (formikProp.values.customerPets?.length > 0 && parseInt(formikProp.values.petNameSeleted) >= 0) {
          kinds = parseInt(formikProp.values.customerPets[formikProp.values.petNameSeleted].kinds)
        }
        setDataOptionPetType(response?.petTypes, kinds)
        setShops(response?.shops?.map((x: any) => { return { label: x.name, value: x.id } }))
      }

    })
    helpers.setValue(0);
  }, []);



  const addNewPet = () => {
    formikProp.submitForm().then((x: any) => {
      let isError = false;
      formikProp.values.customerPets.forEach((x: any, index: number) => {
        if (!checkDateValid(x.birthdayYear, x.birthdayMonth, x.birthdayDay) && !x.birthdayDefault) isError = true
      })
      if (formikProp.isValid && !isError) {
        formikProp.values.customerPets.push({
          name: "",
          kinds: "",
          brand_id: "",
          pet_type_id: "",
          gender: "",
          castration: "",
          birthdayYear: "",
          birthdayMonth: "",
          birthdayDay: "",
          birthday: "",
          purchase_shop_id: "",
          birthdayDefault: "",
        });
        helpers.setValue(parseInt(formikProp.values.petNameSeleted) + 1);
      }
    });
  };

  const handleNamePetKeyUp = (e: any) => {
    // let nameValue = e.target.value;
    // if (nameValue && formikProp.values?.customerPets?.some((x:any) => x.name === nameValue)) {
    //   setIsSameName(true)
    // } else {
    //   setIsSameName(false)
    // }

  }

  const
    setDataOptionPetType = (options: any, kinds: number) => {
      let optionByKinds = options.filter((x: any) => x.kinds === kinds)
      optionByKinds = optionByKinds?.map((x: any) => {
        return { label: x.name , value: x.id }
        // return { label: x.name + (x.kinds === 1 ? " (犬)" : (x.kinds === 2 ? " (猫)" : (x.kinds === 3 ? " (不明)" : ""))), value: x.id }
      })
      optionByKinds.push({ label: '', value: '' })
      setPetTypes(optionByKinds)
    }

  const handleKindsPetChange = (e: any) => {
    setDataOptionPetType(resPetTypes, parseInt(e))
    // { `customerPets.${idx}.kind` }
  }

  return (
    <>
      <Typography component="div" className={classes.root}>
        <Typography className={classes.title}>愛犬・愛猫情報の入力</Typography>
        <Typography component="div" className={classes.boxListPet}>
          <SelectField
            name={petNameSeleted.name}
            options={optionPets}
            // onChange={petChange()}
            placeholder=""
            isMulti={false}
          />
          <Button
            className={classes.newPet}
            type="button"
            variant="contained"
            color="primary"
            onClick={() => {
              addNewPet();
            }}
          >
            ▼もう一頭追加
          </Button>
        </Typography>

        <FieldArray name="customerPets">
          {({ insert, remove, push }) => (
            <div>
              {formikProp.values.customerPets.length > 0 &&
                formikProp.values.customerPets.map(
                  (pet: any, index: number) => {
                    if (index == formikProp.values.petNameSeleted)
                      return (
                        <div className={classes.itemPet} key={index}>
                          <Typography
                            component="div"
                            className={classes.buttonRemove}
                          >
                            {index != 0 && <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              type="button"
                              onClick={() => {
                                remove(index);
                                helpers.setValue(index - 1);
                              }}
                            >
                              削除
                            </Button>}
                          </Typography>
                          <Box
                            className={classes.boxPet}
                            sx={{ display: "grid" }}
                          >
                            {/*identification_number*/}
                            <Item>
                              <LabelTag
                                titleLabel="愛犬・愛猫のお名前"
                                isRequired={true}
                              />
                              <InputPetName
                                name={`customerPets[${index}].name`} maxLength={15} arrayPet={formikProp.values.customerPets}
                              />

                            </Item>
                            <Item>
                              <LabelTag titleLabel="ペットの種類" isRequired={true} />
                              <RadioButtonForKinds name={`customerPets.${index}.kinds`} options={optionRadioKinds} indexPetType={index} handleRadioChange={(value: any) => handleKindsPetChange(value)} />
                            </Item>
                            {/* <Item>
                              <LabelTag
                                titleLabel="よく購入するブランド"
                                isRequired={true}
                              />
                              <SelectField
                                className="custom-select"
                                name={`customerPets.${index}.brand_id`}
                                options={brands}
                                placeholder=""
                                isMulti={false}
                              />
                            </Item> */}
                            <Item>
                              <LabelTag
                                titleLabel="愛犬・愛猫の種類"
                                isRequired={true}
                              />
                              <SelectField
                                className="custom-select"
                                name={`customerPets.${index}.pet_type_id`}
                                options={petTypes}
                                placeholder=""
                                isMulti={false}
                              />
                            </Item>
                            <Item>
                              <LabelTag titleLabel=" 性別" isRequired={true} />
                              <RadioButton name={`customerPets.${index}.gender`} options={optionRadioGenderPet} />
                            </Item>
                            <Item>
                              <LabelTag
                                titleLabel="避妊・去勢"
                                isRequired={true}
                              />
                              <RadioButton name={`customerPets.${index}.castration`} options={optionRadioCastration} />
                            </Item>
                            <Item>
                              <LabelTag titleLabel="誕生日" isRequired={true} />
                              <Typography component="div" style={{ textAlign: 'left' }}>
                                <Typography component="p" >お誕生日がはっきりとわからない場合は、年と月を入れて、日にちは1日にしてください。
                                  年月も分からない場合は、「わからない」を選んでください。</Typography>
                              </Typography>
                              {!pet.birthdayDefault ? false : (pet.birthdayYear = '', pet.birthdayMonth = '', pet.birthdayDay = '')}

                              <SelectDatePicker isDisabledSelect={!pet.birthdayDefault ? false : true} indexKey={index} />
                              <ErrorMessage
                                name={`customerPets.${index}.birthday`}
                                component="div"
                                className={classes.fieldError}
                              />
                              <Typography component="div" style={{ display: 'flex' }}>
                                <CheckboxField
                                  name={`customerPets.${index}.birthdayDefault`}
                                  label="わからない"
                                />
                              </Typography>
                            </Item>
                            {/* <Item>
                              <LabelTag
                                titleLabel="購入店舗"
                                isRequired={false}
                              />
                              <SelectField
                                className="custom-select"
                                name={`customerPets.${index}.purchase_shop_id`}
                                options={shops}
                                placeholder=""
                                isMulti={false}
                              />
                            </Item> */}
                          </Box>
                        </div>
                      );
                  }
                )}
            </div>
          )}
        </FieldArray>
      </Typography>
    </>
  );
};

export default CustomerPetsForm;
