import {
  Box,
  BoxProps,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { MessageDialog } from "components/Common";
import InputField from "components/Common/form-fields/InputField";
import RadioButton from "components/Common/form-fields/RadioButton";
import SelectField from "components/Common/form-fields/SelectField";
import LabelTag from "components/Common/LabelTag";
import { YubinBango } from "components/Common/YubinBango";
import { yubibangos } from "constants/yubibango";
import { FormikValues, useField } from "formik";
import React, { FC, useState } from "react";

interface PropsType {
  formField: FormikValues;
  errors: any;
  touched: any;
}

const yubibangoOptions = yubibangos.map((x) => ({
  label: x,
  value: x,
}));

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: 19,
        fontWeight: "700",
        height: "auto",
        ...sx,
      }}
      {...other}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "30px",
    width: "100%",
    display: "contents",
  },
  regionName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    marginBottom: theme.spacing(2),
  },
  title: {
    backgroundColor: 'rgb(255, 255, 255)',
    fontWeight: 'bold',
    fontSize: '1.4em',
    padding: '30px'
  },
  mr10: {
    marginRight: "10px",
  },
  postalCode: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorCustom: {
    color: '#f44336',
    fontSize: ' 0.75rem',
    textAlign: 'left',
    marginTop: "-16px",
    marginBottom: "16px",
    marginLeft: '14px'
  }
}));

const CustomerForm: FC<PropsType> = (props) => {
  const {
    formField: {
      identificationNumber,
      familyName,
      firstName,
      kanaFamilyName,
      kanaFirstName,
      gender,
      emailAddress,
      verifyEmailAddress,
      phoneNumber,
      postalCode,
      prefectures,
      address1,
      address2,
      address3,
    }, errors, touched
  } = props;
  const classes = useStyles();
  const [field] = useField(postalCode.name);
  const [fieldAddress, meta, helpers] = useField(address1.name);
  const [fieldPrefectures, metaPrefectures, helpersPrefectures] = useField(prefectures.name);
  const postalCodeValue2 = field.value;
  const { setValue } = helpers;
  const optionRadioGender = [{ label: '男', value: '1' }, { label: '女', value: '2' }];
  const [isShowDialogError, setIsShowDialogError] = useState(false);
  const message = "指定の郵便番号はデータがないです。"
  const searchYubibango = (prefecture: string, address: string, isError: boolean = false) => {
    if (isError) {
      setIsShowDialogError(true)
      return
    } 
    helpersPrefectures.setValue(prefecture);
    setValue(address);
  };

  const closeDialog = () => {
    setIsShowDialogError(false)
  }

  return (
    <>
      <Typography component="div" className={classes.root}>
        <Typography className={classes.title}>お客様情報の入力</Typography>
        <Box sx={{ display: "grid" }}>
          {/*name*/}
          <Item>
            <LabelTag titleLabel=" お名前" isRequired={true} />
            <Typography component="div" className={classes.regionName}>
              <Typography className={classes.mr10} component="span">
                姓
              </Typography>
              <InputField name={familyName.name} isShowError={false} maxLength={10} />
            </Typography>
            {errors[familyName.name] && touched[familyName.name] ? (
              <Typography component="p" className={classes.errorCustom}>{errors[familyName.name]}</Typography>
            ) : null}

            <Typography component="div" className={classes.regionName}>
              <Typography className={classes.mr10} component="span">
                名
              </Typography>
              <InputField name={firstName.name} maxLength={10} isShowError={false} />
            </Typography>
            {errors[firstName.name] && touched[firstName.name] ? (
              <Typography component="p" className={classes.errorCustom}>{errors[firstName.name]}</Typography>
            ) : null}
          </Item>

          {/*kana name*/}
          <Item>
            <LabelTag titleLabel=" ふりがな" isRequired={true} />
            <Typography component="div" className={classes.regionName}>
              <Typography className={classes.mr10} component="span">
                姓
              </Typography>
              <InputField name={kanaFamilyName.name} maxLength={15} isShowError={false} />
            </Typography>
            {errors[kanaFamilyName.name] && touched[kanaFamilyName.name] ? (
              <Typography component="p" className={classes.errorCustom}>{errors[kanaFamilyName.name]}</Typography>
            ) : null}
            <Typography component="div" className={classes.regionName}>
              <Typography className={classes.mr10} component="span">
                名
              </Typography>
              <InputField name={kanaFirstName.name} maxLength={15} isShowError={false} />
            </Typography>
            {errors[kanaFirstName.name] && touched[kanaFirstName.name] ? (
              <Typography component="p" className={classes.errorCustom}>{errors[kanaFirstName.name]}</Typography>
            ) : null}
          </Item>

          {/*gender*/}
          <Item>
            <LabelTag titleLabel="性別" isRequired={true} />
            <RadioButton name={gender.name} options={optionRadioGender} />
          </Item>

          {/*email*/}
          <Item>
            <LabelTag titleLabel="メールアドレス" isRequired={true} />
            <InputField name={emailAddress.name} maxLength={50} />
          </Item>

          {/*verify email*/}
          <Item>
            <LabelTag
              titleLabel="メールアドレス（確認）"
              isRequired={true}
            />
            <InputField name={verifyEmailAddress.name} maxLength={50} />
          </Item>

          {/*phone number*/}
          <Item>
            <LabelTag titleLabel="電話番号（ハイフンなし）" isRequired={true} />
            <InputField name={phoneNumber.name} maxLength={11} />
          </Item>

          {/*postal code*/}
          <Item>
            <LabelTag titleLabel="郵便番号（ハイフンなし）" isRequired={true} />
            <Typography component="div" className={classes.postalCode}>
              <InputField name={postalCode.name} maxLength={7} isShowError={false} />
              <YubinBango
                searchYubibango={searchYubibango}
                value={postalCodeValue2}
              />
            </Typography>
            {errors[postalCode.name] && touched[postalCode.name] ? (
              <Typography component="p" style={{marginTop: 0}} className={classes.errorCustom}>{errors[postalCode.name]}</Typography>
            ) : null}
          </Item>

          {/*prefectures*/}
          <Item>
            <LabelTag titleLabel="都道府県" isRequired={true} />
            <SelectField
              className="custom-select"
              name={prefectures.name}
              options={yubibangoOptions}
              placeholder=""
              isMulti={false}
            />
          </Item>

          {/*address1*/}
          <Item>
            <LabelTag titleLabel="住所" isRequired={true} />
            <InputField name={address1.name} maxLength={30} />
          </Item>

          {/*address2*/}
          <Item>
            <LabelTag titleLabel=" 丁目・番・号" isRequired={true} />
            <InputField name={address2.name} maxLength={20} />
          </Item>

          {/*address3*/}
          <Item>
            <LabelTag titleLabel="建物名・部屋番号等" isRequired={false} />
            <InputField name={address3.name} maxLength={30} />
          </Item>
        </Box>
      </Typography>
      <MessageDialog isOpen={isShowDialogError} closeModal={closeDialog} title="確認" description={message}/>
    </>
  );
};

export default CustomerForm;
