import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(255, 255, 255)',
    },

    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        textAlign: 'center',
    },
    subTitle: {
        fontWeight: 'bold',
        fontSize: '1.4em',
        padding: '30px',
        textAlign: 'center'
    },
    description: {
        fontWeight: 'bold',
        fontSize: '13px',
        padding: '10px',
        textAlign: 'left'
    },

}));
const RegisteSuccess = () => {
    const classes = useStyles();

    return (
        <Typography component="div" className={classes.root} >
            <Typography component="h1" className={classes.title} >
                2024年のカレンダー写真へのご応募を頂き
            </Typography>
            <Typography component="h1" className={classes.title} >
            誠にありがとうございました！
            </Typography>
            <Typography component="p" className={classes.description} >
                ●確認の為、お客様が入力されたメールアドレス宛に返信メールをお送りいたしました。
                返信メールが届かない場合は、迷惑メールボックスに振り分けられている可能性がございます。
                また、携帯電話会社のメールをお使いの場合（ドコモ,au,ソフトバンク）ドメイン登録を行わ
                ないと届かない場合がございますので、各携帯電話会社の設定画面にて、ベッツ・チョイス・
                ジャパンのドメイン（vcj.jp）の登録をお試しください。
                設定方法は各携帯電話会社へお問い合わせください。
            </Typography>
            <Typography component="p" className={classes.description} >
                ●誠に申し訳ございませんが、採用または不採用のお問合せには答えられません。2023年
                11月末頃にカレンダーが出来上がりますので、それまで楽しみにお待ちください。
            </Typography>
            <Typography component="p" className={classes.description} >
                ●採用・不採用にかかわらずご応募頂いた皆様へ、ご応募時にご登録頂いたご住所に
                出来上がったカレンダーを1部お送りさせて頂きます。但し、お引越しなどでご住所等が
                変わられるとお送りできないことがございます。お客様情報に変更があった場合は弊社
                までご一報頂ければ引き続きカレンダーをお送りさせて頂きます。
            </Typography>
        </Typography>
    )
}

export default RegisteSuccess
