import { formModel } from "./form-model"

const {
  formField: {
    identificationNumber,
    familyName,
    firstName,
    kanaFamilyName,
    kanaFirstName,
    gender,
    emailAddress,
    verifyEmailAddress,
    phoneNumber,
    postalCode,
    prefectures,
    address1,
    address2,
    address3,
    customerPets,
    shopIdOther,
    campaignId,
    petIdImg1s,
    petIdImg2s,
    petNameImg1s,
    petNameImg2s,
    imageFile1,
    imageFile2,
    petNameSeleted,
    agreeTerm,
    agreeTerm1,
    agreeTerm2
  },
} = formModel

export const initialValues = {
  [identificationNumber.name]: "",
  [firstName.name]: "",
  [familyName.name]: "",
  [kanaFamilyName.name]: "",
  [kanaFirstName.name]: "",
  [gender.name]: "",
  [emailAddress.name]: "",
  [verifyEmailAddress.name]: "",
  [phoneNumber.name]: "",
  [postalCode.name]: "",
  [prefectures.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [address3.name]: "",
  [customerPets.name]: [
    {
      name: '',
      kinds: '',
      brand_id: '',
      pet_type_id: '',
      gender: '',
      castration: '',
      birthdayYear: '',
      birthdayMonth: '',
      birthdayDay: '',
      birthday: '',
      purchase_shop_id: '',
      birthdayDefault: ''
    },
  ],
  [shopIdOther.name]: "",
  [campaignId.name]: "",
  [petIdImg1s.name]: "",
  [petIdImg2s.name]: "",
  [petNameImg1s.name]: "",
  [petNameImg2s.name]: "",
  [imageFile1.name]: "",
  [imageFile1.name]: "",
  [petNameSeleted.name]: "",
  [agreeTerm.name]: "",
  [agreeTerm1.name]: "",
  [agreeTerm2.name]: "",
}
