import { ListResponse } from "models"
import axiosClient from "./axiosClient"

const yubinbangoApi = {
    getYubinbango(yubin3: string): Promise<ListResponse<any>> {
        const url = `https://yubinbango.github.io/yubinbango-data/data/${yubin3}.js`
        return axiosClient.get(url)
    },
    
}

export default yubinbangoApi